/* You can add global styles to this file, and also import other style files */

@import 'node_modules/ngx-toastr/toastr.css';
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


.contenedor {
  position: relative;
  text-align: center;
  height: 300px;
}

input {
  border: 1px solid #ced4da !important;
}

.texto_over {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  size: 10%;
}

table {
  width: 100%;
  table-layout: auto !important;
}

html {
  height: 100%;
}

.navbar {
  background-color: #0b172f;
  height: 65px;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.top-buffer {
  margin-top: 20px;
}

.side-space {
  margin-right: 10px;
}

.side-space-second {
  margin-right: 50%;
}

.top-buffer {
  margin-top: 20px;
}

.container-fluid {
  padding: 0px !important;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem;
  width: 100vw !important;
}

.table-div {
  padding: 20px;
}

.bg-img {
  background-image: url('/assets/images/aeromexico-frontPagev2.jpg') !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.headertable {
  background-color: #0b172f !important;
  color: #ffffff !important;
  text-align: center !important;
  border: none !important;
}

.ui-sortable-column-icon {
  color: #ffffff !important;
}

.ui-table-caption {
  background-color: #ffffff !important;
  border: none !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.styleTable,
.ui-paginator-bottom {
  border-left: none !important;
  border-right: none !important;
  text-align: center !important;
}

.styleButtonTable,
.styleButtonModal {
  background-color: #2f2fb6 !important;
  color: white !important;
  padding: 5px 5px 8px 8px !important;
  font-size: .875rem !important;
  line-height: 1.5 !important;
  border-radius: .2rem !important;
  border: 1px solid transparent !important;
  cursor: pointer !important;
  text-align: center !important;
  align-content: center !important;
  align-content: center !important;
}

.buttonModal-Footer{
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.styleButtonModal {
  background-color: #4949d0 !important;
}

.close {
  outline: none !important;
  background-color: transparent;
  border: transparent;
}

.ui-dropdown-label {
  border-left: 1px solid #c8c8c8 !important;
  border-right: 1px solid #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #848484 !important;
}

.ui-dropdown-trigger {
  border-left: 1px solid transparent !important;
  background-color: #f4f4f4 !important;
  border-right: 1px solid #c8c8c8 !important;
}

.containerFiltro {
  width: 100% !important;
  margin-bottom: 10px !important;
  padding-left: 5px;
  height: 25px;
}

.btnSearch {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
  border: 1px solid transparent;
  cursor: pointer;
}

.paddingLeft {
  padding-left: 0px;
}

.titleComponent {
  position: relative !important;
  top: 50px !important;
  font-size: 40pt !important;
  text-align: left !important;
  padding-left: 105px !important;
  color: white !important;
}

.ui-tree {
  width: 100% !important;
}

.marginModal {
  margin-right: 48% !important;
}

.modal-title{
  font-weight: bold !important;
  font-size: 18px !important;
  vertical-align: middle !important;
  padding-top: 12px !important;
}

.modalSendNotice {
  width: 182% !important;
  margin-left: 4% !important;
}

.obligation {
  color: red !important;
}

.marginTopModal {
  margin-top: -45px !important;
}

.styleIcon {
  width: 40px !important;
  height: 50px !important;
}

.iconButton {
  margin-top: 15px !important;
}

.iconButtonTwo {
  margin-top: 15px !important;
  margin-left: 5px !important
}

.iconCenter {
  margin-top: 7px !important;
}

.titleCat {
  margin-bottom: -40px !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: block !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: 0 -307px !important;
  width: 100%;
  height: 180px !important;
}

.titleCatREgions {
  background-position: 0 -210px !important;
}

.titleCatStation {
  background-position: 0 -105px !important;
}

.titleStatistics {
  background-position: 0 -105px !important;
}

.titleFleetList {
  background-position: 0 -307px !important;
}

.titleBoardingTable {
  background-position: 0 -105px !important;
}

.titleRestockCap {
  background-position: 0 -145px !important;
}

.titleReportIrregularities {
  background-position: 0 -105px !important;
}

.titleTsus {
  background-position: 0 -307px !important;
}

.titleMatrix {
  background-position: 0 -307px !important;
}

.titleStatistics {
  background-position: 0 -105px !important;
}

.titleCatProvider {
  background-position: 0 -80px !important;
}

.carouselContainer {
  height: 440px !important;
}

.titleCatCycle,
.titleCatCodeServ,
.titleGralConsult,
.titleCatUser {
  background-position: 0 -115px !important;
}

.titleMaster,
.titleConsultMeal,
.titleGraphics,
.titleListBar,
.titleGuidesServices,
.titleCatMaterials,
.titleManuals,
.titleProducts,
.titleEquipment,
.titleDigitalMenu {
  background-position: 0 -307px !important;
}

.titleFoods {
  background-position: 0 -85px !important;
}

.titleCatPotential {
  background-position: 0 -70px !important;
}

.containerCycle {
  margin-top: 0% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
  margin-bottom: 5% !important;
}

.containerServiceCode {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
}

.containerBoardingTable {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
}

.containerRestockCap {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
}

.containerGraphics {
  margin-top: 7% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
}

.containerPotentialConsultation,
.containerCatProvider,
.containerCatRegions,
.containerCatStation,
.containerCatMaterials,
.containerCatStatistics,
.containerReportsIrregularities,
.containerTsus,
.containerGuidesServices,
.containerMatrix,
.containerStatistics,
.containerConsultNotice,
.containerGralConsult,
.containerCatUser,
.containerManuals,
.containerFleetList,
.containerFoods,
.containerDigitalMenu,
.containerProducts,
.containerIrregularityCriteria,
.containerPreslectEmailsCatalog,
.containerEquipment {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
}

/*   .ui-table-wrapper.wrapperFleet {
    height:9px!important;
    overflow:scroll!important;

} */
/* div.ui-table.scrollable-body.scrolltableFleet{
  max-height: 480px!important;
} */
tbody.ui-table.tbody.bodyFleet {
  position: fixed;
  padding-top: 117px;
}

thead.ui-table-thead.headFleet {
  position: sticky !important;
}

div.ui-table-scrollable-body {
  max-height: 400px !important;
}

body .ui-table .ui-table-thead>tr>.columFamily {
  padding: 0.571em 5.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
}

body .ui-table .ui-table-thead>tr>.columMeals {
  padding: 0.571em 5.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
}

body .ui-table .ui-table-thead>tr>.columOvens {
  padding: 0.571em 6.7em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
}

body .ui-table .ui-table-thead>tr>.columNotes {
  padding: 0.571em 7.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
}

body .ui-table .ui-table-thead>tr>.columyEntertainment {
  padding: 0.571em 3.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columjEntertainment {
  padding: 0.571em 4.9em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columjHeadphones {
  padding: 0.571em 5.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columyHeadphones {
  padding: 0.571em 4.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columjWC {
  padding: 0.571em 4.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columYwc {
  padding: 0.571em 4.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columjStationery {
  padding: 0.571em 4.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}

body .ui-table .ui-table-thead>tr>.columyStationery {
  padding: 0.571em 6.857em !important;
  border: none !important;
  font-weight: 700;
  color: #ffffff;
  background-color: #0b172f !important;
  text-align: center;
}





.containerMaster {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 25% !important;
  margin-bottom: 5% !important;
}

.containerMasterPrincipal {
  margin-top: 6% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 5% !important;
  text-align: center !important;
}

.containerListBar {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: -5% !important;
  text-align: center !important;
}

.containerMain {
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: -5% !important;
  text-align: center !important;
}

.containerReportsIrregularities {
  margin-top: 5% !important;
}

.containerTsus {
  margin-top: 3% !important;
}

.containerFiltro,
.containerFiltroCycles {
  width: 100% !important;
}

.ui-datepicker-trigger {
  height: 35px !important;
}

.ui-datepicker {
  top: 35px !important;
  height: 376px !important;
  padding-top: 2px !important;

}

.iconEdit,
.iconEye {
  margin-left: 2px !important;
  margin-bottom: 1px !important;
}

.iconBook {
  margin-left: 2px !important;
}

.iconDelete {
  margin-left: 1px !important;
  margin-bottom: 1px !important;
}

.fontCalendar {
  font-weight: 400 !important;
}

.buttonYes {
  width: 35px !important;
}

li.ui-state-default {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  outline: none !important;
}

.buttonDeshabilitado {
  cursor: none;
  background-color: #c8c8c8;
  color: white;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
  border: 1px solid transparent;
}

.fontSizeLabelModal {
  font-size: 15px !important;
  color: #000000 !important;
}

.topMarginModal {
  margin-top: -5px !important;
  margin-bottom: 10px !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.uppercaseSearch {
  text-transform: uppercase !important;
  height: 25px;
}

.divTitle {
  width: 100%;
  margin-left: initial !important;
}

.buttonAddGral {
  text-align: right !important;
  margin-top: 5px !important;
}

.center {
  text-align: center !important;
}

label.ui-dropdown-label {
  background: #fff !important;
  color: #000000 !important;
}

div.ui-dropdown-trigger {
  background: #fff !important;
}

.carouselContainer {
  height: 380px !important;
}

.carouselImage1 {
  margin-top: -280px !important
}

.carouselImage2 {
  margin-top: -164px !important
}

.carouselImage3 {
  margin-top: -405px !important
}

.carouselImage4 {
  margin-top: -425px !important
}

.carouselImage5 {
  margin-top: -440px !important
}

.carouselImage6 {
  margin-top: -400px !important
}

@media only screen and (max-width: 768px) {
  .container-fluid {
    padding: 0px;
    width: 91.9vw;
  }

  .buttonSaveResponsive {
    margin-left: 70% !important;
  }

  .ui-dropdown {
    height: auto !important;
  }

  .containerFiltro {
    width: 100% !important;
    height: 25px;
  }

  .titleComponent {
    padding-left: 50px !important;
    font-size: 25pt !important;
  }

  .ui-tree {
    width: 98% !important;
    font-size: 13px !important;
  }

  .containerCycle {
    margin-left: 2% !important;
  }

  .containerServiceCode,
  .containerPotentialConsultation,
  .containerCatProvider,
  .containerCatRegions,
  .containerCatStation,
  .containerCatMaterials,
  .containerCatStatistics,
  .containerBoardingTable,
  .containerConsultNotice,
  .containerGralConsult,
  .containerCatUser,
  .containerGraphics,
  .containerMaster,
  .containerMasterPrincipal,
  .containerManuals,
  .containerFoods,
  .containerProducts,
  .containerEquipment,
  .containerIrregularityCriteria {
    margin-top: 16% !important;
  }
}

@media only screen and (max-width: 2000px) {
  .carouselContainer {
    height: 325px !important;
  }
}

@media only screen and (max-width: 3500px) and (min-width: 2500px) {
  .carouselContainer {
    height: 750px !important;
  }

  .carouselImage1 {
    margin-top: -380px !important
  }

  .carouselImage2 {
    margin-top: -304px !important
  }

  .carouselImage3 {
    margin-top: -605px !important
  }

  .carouselImage4 {
    margin-top: -625px !important
  }

  .carouselImage5 {
    margin-top: -900px !important
  }

  .carouselImage6 {
    margin-top: -600px !important
  }
}

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type=checkbox]:checked+.lever {
  background-color: #84c7c1;
}

.switch label input[type=checkbox]:checked+.lever:before,
.switch label input[type=checkbox]:checked+.lever:after {
  left: 18px;
}

.switch label input[type=checkbox]:checked+.lever:after {
  background-color: #26a69a;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: #4d88ff;
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:before,
.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  -webkit-transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
  transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
}

.switch label .lever:before {
  background-color: rgba(38, 166, 154, 0.15);
}

.switch label .lever:after {
  background-color: #F1F1F1;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

input[type=checkbox]:checked:not(:disabled)~.lever:active::before,
input[type=checkbox]:checked:not(:disabled).tabbed:focus~.lever::before {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
  background-color: rgba(38, 166, 154, 0.15);
}

input[type=checkbox]:not(:disabled)~.lever:active:before,
input[type=checkbox]:not(:disabled).tabbed:focus~.lever::before {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
  background-color: rgba(0, 0, 0, 0.08);
}

.switch input[type=checkbox][disabled]+.lever {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
}

.switch label input[type=checkbox][disabled]+.lever:after,
.switch label input[type=checkbox][disabled]:checked+.lever:after {
  background-color: #949494;
}

@media (min-width: 992px) {
  .modal-lg.modalHistorial {
    max-width: 95vw !important;
  }
}

/*Estilos para la carga de archivos*/
.custom-file-button input[type=file] {
  margin-left: -2px !important;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}

.cdk-overlay-container {
  z-index: 10000;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
